import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import type { IntegrationData, StatusData, ThankYouPublicData } from '@wix/pricing-plans-router-utils';
import type { ThankYouPageSettings } from '@wix/pricing-plans-utils/client-data';
import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../constants';
import { createPlansApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { WarmupData } from '../../services/WarmupData';
import { hasMultiplePages } from '../../utils/multiple-pages';
import { Navigation } from '../PackagePicker/controller/Navigation';
import { Router } from '../PackagePicker/controller/Router';
import { StatusController } from '../PackagePicker/controller/StatusController';

const DemoStatusData: StatusData = {
  ownerDemo: true,
  successful: true,
  planName: 'Demo Plan',
  purchaseData: {
    checkoutData: { integrationData: {}, planId: 'DemoPlanId' },
    orderId: 'DemoOrderId',
    snapshotId: 'DemoSnapshotId',
  },
};

export interface ThankYouWidgetProps {
  settings?: ThankYouPageSettings;
  successful?: boolean;
  translatedError?: string;
  order?: Order;
  startDate?: string;
  ownerDemo?: boolean;
  integrationData: IntegrationData;
  navigateBackToCheckout(): void;
  navigateFromStatusPage(): void;
  biThankYouPageCtaButtonClick(): void;
  biThankYouPageOnLoad(): void;
  biUpgradeReferralClick(referralInfo: string): void;
  metaSiteId?: string;
}

export const createController: CreateControllerFn = async ({ flowAPI }) => {
  const { wixCodeApi, appParams, compId, setProps: untypedSetProps } = flowAPI.controllerConfig;

  const setProps: (props: Partial<ThankYouWidgetProps>) => void = untypedSetProps;

  const plansApi = createPlansApi(flowAPI.httpClient);
  const analytics = new Analytics(wixCodeApi.window);
  const warmupData = new WarmupData(compId, wixCodeApi, flowAPI);
  const nav = new Navigation(
    wixCodeApi,
    flowAPI,
    appParams.appDefinitionId,
    flowAPI.experiments.enabled(EXPERIMENTS.INSTALL_SPLIT_PAGES) && (await hasMultiplePages(wixCodeApi)),
  );
  const router = new Router(setProps, nav, analytics, wixCodeApi, flowAPI);
  const status = new StatusController(setProps, wixCodeApi, flowAPI, router, plansApi, warmupData);

  return {
    async pageReady() {
      const routerData = wixCodeApi.window.getRouterPublicData<ThankYouPublicData>();

      const plan = routerData?.plan ?? undefined;
      const statusData = routerData?.statusData ?? DemoStatusData;

      await status.update(statusData, plan);
    },
    async updateConfig(_$w, newConfig) {},
  };
};
